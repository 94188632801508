import React, { Component } from 'react';
import NotificationAlert from "react-notification-alert";
import CarsBrands from '../components/CarsBrands'

class CarsDb extends Component {
    constructor(props) {
        super(props);
        this.notifRef = React.createRef()
        this.state = {
        };
    }
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    render() {
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <CarsBrands showNotification={this.showNotification} />
            </div>
        );
    }
}

export default CarsDb;
