const dict =
{
    "ar": {
        next: "تابع",
        myOffer: "عرضي",
        storeAddress: "عنوان متجري",
        createStore: "إنشاء متجر على الانترنت",
        login: "تسجيل الدخول",
        username: "اسم المستخدم",
        password: "كلمه السر",
        signin: 'إفتح حساب',
        email: 'بريد الالكتروني',
        error1_title: "انتباه",
        error1_text: "يجب أن يحتوي اسم المستخدم على 4 أحرف على الأقل",
        error2_text: "يجب إكمال جميع الحقول",
        error3_text: "البريد الإلكتروني غير صحيح",
        error4_text: "يجب عليك إضافة صورة واحدة على الأقل",
        error5_text: "يجب عليك فتح حساب لاستخدام هذه الميزة",
        error6_text: "لا يمكنك إرسال رسالة إلى نفسك.",
        ask_del: "هل أنت متأكد أنك تريد حذف هذا الإعلان؟",
        validate: "تأكيد",
        noShipping: "التوصيل غير متوفر",
        yesShipping: "توصيل مجاني",
        shippingPayment: "الدفع عند الاستلام",
        price: "السعر",
        desc: "وصف (اختياري)",
        phone: "رقم الهاتف",
        search: "بحث...",
        cat: "الفئة",
        scat: "الفئة الفرعية",
        currency: "دج",
        limite_pictures: "عدد الصور محدود بـ 3",
        min_picture: "يجب أن تكون هناك صورة واحدة على الأقل",
        tempLinkText: "يمكن الوصول إلى متجرك عبر الإنترنت مؤقتًا على العنوان التالي:",
        subdomainLinkText: "يمكن الوصول إلى متجرك عبر الإنترنت على العنوان التالي:",
        subdomainLinkTextWait: "يرجى الانتظار بضع ساعات حتى يتم تنشيط عنوانك الجديد",
        limitProd: "عدد من المنتجات",
        limitPhotos: "عدد الصور لكل منتج",
        limitSlides: "عدد صور الترحيب (الشرائح)",
        fbChat: "Chat Messenger في المتجر",
        domainName: "استضافة اسم المجال الخاص بك (mysite.com)",
        ssl: "شهادة أمان SSL (https)",
        apiShipping: "Yalidine Express API (تكاليف التوصيل وإنشاء الحزم تلقائيًا)",
        apiLogin: "API تسجيل الدخول Facebook و Google",
        expireDate: "انتهاء صلاحية",
        contactInfosTitle: "معلومات الاتصال",
        contactInfosText: "سيتم عرض هذه المعلومات في متجرك",
        address: "العنوان بالفرنسية",
        orderAddress: "العنوان",
        addressAr: "العنوان بالعربية",
        linkFb: "رابط صفحة الفيسبوك",
        linkInsta: "رابط صفحة Instagram",
        save: "تسجيل",
        renew: "تجديد",
        renewText: "يجب عليك تجديد عرضك كل شهر في منطقة العملاء الخاصة بك ، وإلا سيتم تعليق متجرك. هذا من أجل محاربة المتاجر المهجورة",
        logoTitle: "الشعار",
        logoText: "سيتم عرض هذه المعلومات للزبون في متجرك عبر الإنترنت.",
        importImage: "استيراد صورة",
        fileTypeError: "النوع غير مدعوم",
        fileSizeError: "الملف كبير جدًا",
        categories: "الفئات",
        subCategories: "الفئات الفرعية",
        noCat: "ليس لديك فئة مسجلة",
        noScat: "ليس لديك أي فئة فرعية مسجلة في الفئة المختارة",
        catFr: "الفئة باللغة الفرنسية",
        catAr: "الفئة باللغة العربية",
        scatFr: "الفئة الفرعية باللغة الفرنسية",
        scatAr: "الفئة الفرعية باللغة العربية",
        products: "قائمة المنتجات",
        addProd: "إضافة منتج",
        prodInfos: "معلومات عن المنتج",
        designation: "تعيين المنتج",
        brand: "علامة تجارية",
        promoTx: "نسبة التخفيض",
        pricePromo: "السعر المخفض",
        picsTitle: "صور المنتج",
        picsText: "أرسل صور المنتج ، ويقتصر وزن كل صورة على 500 كيلو بايت",
        slidesTitle: "تمرير صور الترحيب (الشرائح)",
        slidesText: "حدد الصور الترحيبية وأرسلها ، ويمكن أن تكون صورًا لمنتج ترويجي أو إعلاني ، ويقتصر الوزن على 500 كيلوبايت لكل صورة",
        btnUploadLogoText: "تحميل الصورة",
        update: "تحديث",
        options: "خيارات",
        hideProduct: "إخفاء المنتج",
        enablePromo: "تفعيل التخفيض",
        setNewBadge: "شارة (جديد)",
        prodVariants: "متغيرات المنتج",
        variantText: "<i class=\"nc-icon nc-alert-circle-i\" style=\"font-size: 24px\"></i><br>يتيح لك هذا القسم تحديد الاختيارات المقدمة للعميل من حيث الألوان والأحجام وما إلى ذلك. <b>إذا كانت بعض القيم مفقودة من القوائم ، يمكنك الاتصال بنا لإضافتها.</b>",
        variantColorAsk: "هل هذا المنتج متوفر بألوان متعددة؟",
        variantSizeAsk: "هل هذا المنتج متوفر بمقاسات متعددة؟",
        variantFeetAsk: "هل هذا المنتج متوفر بمقاسات متعددة للأحذية؟",
        variantVolumeAsk: "هل هذا المنتج متوفر في عدة أحجام؟",
        variantWeightAsk: "هل هذا المنتج متوفر في عدة أوزان (كغ)؟",
        generateVariants: "توليد المتغيرات",
        yes: "نعم",
        no: "لا",
        noColorOptions: "لم يتم العثور على هذا اللون.",
        selectOption: "اختيار...",
        free: "مجاني",
        hasVariantsAsk: " هل هذا المنتج متوفر بألوان أو مقاسات أو مقاسات الحذاء أو أحجام أو أوزان متعددة؟",
        color: "اللون",
        size: "مقاس",
        feet: "مقاس الحذاء",
        volume: "الحجم",
        weight: "الوزن",
        any: "لا شيء",
        firstOptionAsk: "انقر فوق أحد الخيارات",
        secondOptionAsk: "انقر على الخيار الثاني إذا كان متوفرا",
        qt: "الكمية",
        qtReal: "الكمية الحقيقية",
        qtVirt: "الكمية الافتراضية",
        inputGroupVariantText: "<i class=\"nc-icon nc-alert-circle-i\" style=\"font-size: 24px\"></i><br>هذه الحقول تسمح لك بإدخال كمية وسعر المتغيرات في آن واحد. سيتم إجراء تعديل على كافة المتغيرات من هذا المنتج.<br><b>تحذير: إذا كنت ترغب في تغيير قيمة متغير ، فمن المستحسن القيام بذلك بشكل فردي في الحقول الخاصة بهم.</b>",
        linkTwitter: "رابط تويتر",
        brandName: "اسم المتجر على الموقع",
        wats: "رقم WhatsApp",
        cgvTitle: "الشروط العامة للبيع",
        cgvText: "تُعلم الشروط العامة للبيع العميل بشروط السداد وأوقات التسليم والضمان وشروط الإرجاع والمعلومات الأخرى التي يجب أن يكون العميل على علم بها قبل الشراء.",
        couponCode: "قسيمة التخفيض",
        discountPercent: "نسبة مئوية (%)",
        discountAmount: "مبلغ ثابت (دج)",
        discountValue: "قيمة",
        discountType: "نوع التخفيض",
        addCoupon: "قم بإنشاء قسيمة التخفيض",
        deliveryModeText: "سيكون للمشتري خيار اختيار استلام طلبه مباشرة في المتجر ، أو الاستفادة من خدمة التوصيل ، إذا تم تفعيلها. يتم تقديم خدمة الاستلام من المتجر للمشتري بشكل افتراضي. <br> من خلال تنشيط خيار التسليم ، فإنك تضمن هذه الخدمة للمشتري ، إما بنفسك أو من خلال شركة توصيل.",
        enableDelivery: "تفعيل التسليم",
        enableApiYalidine: "قم بتنشيط Yalidine Express API",
        yalidineApiText: '<i class="nc-icon nc-alert-circle-i" style="font-size: 24px" ></i><br>مع yalidine express api ، سيتم عرض رسوم التوصيل تلقائيًا على موقع الويب الخاص بك. أيضًا ، سيتم إنشاء طرد تلقائيًا عند قبول الطلبات',
        fees: "تكاليف",
        wilaya: "ولاية",
        communes: "البلديات",
        noOrder: "لم تتلق أي طلبات حتى الآن",
        deliveryMode: "طريقة الشراء",
        inStore: "الاستلام من المتجر",
        orders: "قائمة الطلبيات",
        orderNumber: "رقم الطلب",
        customer: "الزبون",
        new: "طلبية جديدة",
        canceled: "ألغيت",
        preparing: "تحضير",
        ready: "جاهز",
        shipping: "جاري التوصيل",
        received: "تم الاستلام",
        archived: "مؤرشف",
        archivedOrders: "الطلبيات من الأرشيف",
        orderDate: "التاريخ و الساعة",
        orderInfos: "معلومات الطلب",
        totalPrice: 'السعر الكلي',
        discount: 'تخفيض',
        total: 'مجموع',
        fee: 'تكاليف التوصيل',
        totalToPay: 'مجموع للدفع',
        refuseOrder: "إلغاء",
        acceptOrder: "موافقة",
        qtInStock: "الكميه في المخزن",
        qtToAdd: "الكمية المراد إضافتها",
        reference: "مرجع",
        messengerPlugin: "البرنامج المساعد للدردشة على Facebook Messenger",
        messengerPluginText: "البرنامج المساعد للدردشة على Facebook Messenger",
        shopColors: "الوان المتجر",
        colorNavBg: "لون شريط التنقل",
        colorNavText: "لون النص في شريط التنقل",
        colorMenuBg: "لون قائمة الفئة",
        colorMenuText: "لون نص قائمة الفئة",
        primaryColor: "اللون الأساسي",
        secondaryColor: "اللون الثانوي",
        reset: "إعادة تعيين الافتراضي",
        stock: "الكميه في المخزن",
        noStock: "لا يوجد مخزون",
        stopDesk: "Stop Desk",
        homeDelivery: "التوصيل إلى المنزل",
        validationOnProgress: "جاري التأكيد ...",
        migrate: "الانتقال إلى عرض أعلى",
        upgradeText: "هل تبحث عن المزيد من المزايا والخدمات؟",
        nbUsers: "الزبائن المسجلين",
        refresh: "تحديث",
        productsCount: "عدد المنتجات المضافة",
        ordersCount: "الطلبات المعلقة",
        revenue: "الدخل لهذا الشهر",
        revenuePerMonth: "الدخل الشهري",
        sellsCount: "عدد المبيعات شهريًا",
        bestSellers: "الأكثر مبيعًا",
        top5: "أفضل 5 منتجات مبيعًا",
        lon: "خط الطول",
        lat: "خط العرض",
        dashboard: "لوحة القيادة",
        needDhashboard: "هل تحتاج إلى المزيد من الوظائف على لوحة القيادة؟",
        catalogPlugin: "المكون الإضافي لـ Facebook Catalog",
        catalogPluginText: '<i class = "nc-icon nc-alert-circ-i" style = "font-size: 24px"> </i> <br> بفضل هذا المكون الإضافي ، يمكنك استيراد منتجاتك من كتالوج Facebook والعرض في متجر صفحتك على Facebook <br> انقر فوق الزر أدناه في كل مرة تقوم فيها بتحديث معلومات منتجك. <br> عند استخدام هذا المكون الإضافي لأول مرة ، ستحتاج إلى نسخ / لصق الرابط الذي تم إنشاؤه واستخدامه كمصدر بيانات . اتبع الخطوات لإنشاء مصادر البيانات على مدير مبيعات Facebook ، واختر الخيارات التالية: مصادر البيانات => خلاصات البيانات => موجز مجدول',
        catalogPluginButtonText: "تحديث مصدر البيانات",
        month: "الشهر",
        customisable: "قابل للتخصيص (ألوان)",
    },
    "fr": {
        customisable: "Personnalisable (couleurs)",
        month: "Mois",
        catalogPluginButtonText: "Actualiser la source de données",
        catalogPlugin: "Facebook Catalog",
        catalogPluginText: '<i class="nc-icon nc-alert-circle-i" style="font-size: 24px" ></i><br>Grâce à ce plugin, vous pouvez importer vos produits sur le catalogue Facebook, et les afficher sur la boutique de votre page Facebook<br>Cliquez sur le bouton ci-dessous à chaque fois que vous mettez à jour les informations de vos produits.<br>Lors de la première utilisation de ce plugin, vous devrez copier/coller le lien généré et l\'utiliser comme source de données. Suivez les étapes de création de sources de données sur le gestionnaire de ventes de Facebook, en choisissant les options suivantes: Sources de données => Flux de données => Fil programmé',
        needDhashboard: "Besoin de plus de fonctionnalités sur le tableau de bord?",
        dashboard: "Tableau de bord",
        lon: "Longitude",
        lat: "Latitude",
        bestSellers: "Meilleures ventes",
        top5: "Top 5 des produits les plus vendus",
        sellsCount: "Nombre de ventes par mois",
        revenuePerMonth: "Revenus par mois",
        revenue: "Revenus pour ce mois",
        ordersCount: "Commandes en attente",
        productsCount: "Nombre de Produits ajoutés",
        refresh: "Actualiser",
        nbUsers: "Clients enregistrés",
        upgradeText: "Êtes-vous à la recherche de plus de fonctionnalités et de services? S'il vous plait, jetez un oeil sur notre offre BUSINESS",
        migrate: "Migrer vers une offre supérieure",
        validationOnProgress: "Validation en cours...",
        homeDelivery: "Livraison à domicile",
        stopDesk: "Stop Desk",
        noStock: "Aucun stock n'a été trouvé",
        stock: "Stock du produit",
        reset: "Réinitialiser les couleurs",
        colorNavBg: "Couleur de la barre de navigation",
        colorNavText: "Couleur du text de la barre de navigation",
        colorMenuBg: "Couleur du menu des catégories",
        colorMenuText: "Couleur du text du menu des catégories",
        primaryColor: "Couleur Principale",
        secondaryColor: "Couleur Secondaire",
        shopColors: "Couleurs de la boutique",
        messengerPluginText: '<i class="nc-icon nc-alert-circle-i" style="font-size: 24px" ></i><br>Grâce à ce plugin, vous pouvez ajouter le Chat Facebook de votre page sur votre siteweb<br>Il suffit de copier/coller l\'ID de votre page sur le champ en dessous',
        messengerPlugin: "Chat Facebook Messenger",
        reference: "Réf",
        qtToAdd: "Quantité à ajouter",
        qtInStock: "Quantité en stock",
        refuseOrder: "Annuler",
        acceptOrder: "Confirmer",
        totalPrice: 'Sous-total',
        discount: 'Réduction',
        total: 'Total Achat',
        fee: 'Frais de livraison',
        totalToPay: 'Total à payer',
        orderInfos: "Informations sur la commande",
        orderDate: "Date et heure",
        archivedOrders: "Commandes archivées",
        new: "Nouvelle commande",
        canceled: "Annulée",
        preparing: "En préparation",
        ready: "Prête",
        shipping: "En livraison",
        received: "Livraison terminée",
        archived: "Archivée",
        customer: "Client",
        orderNumber: "N° Commande",
        orders: "Liste des Commandes",
        inStore: "Retrait en magasin",
        deliveryMode: "Mode d'achat",
        noOrder: "Vous n'avez encore reçu aucune commande",
        communes: "Communes",
        fees: "Frais",
        wilaya: "Wilaya",
        yalidineApiText: '<i class="nc-icon nc-alert-circle-i" style="font-size: 24px" ></i><br>l\'API de Yalidine express, vous permet d\'afficher aux acheteurs les frais de livraison en allant les chercher à la source. Elle vous permet également de créer vos colis automatiquement sur votre page d\'administation Yalidine depuis votre page d\'administration de MyDzStore en acceptant les commandes.',
        enableApiYalidine: "Activer l'API Yalidine Express",
        enableDelivery: "Activer la livraison",
        deliveryModeText: '<i class="nc-icon nc-alert-circle-i" style="font-size: 24px" ></i><br>Votre acheteur aura la possilibé de choisir de récupérer sa commande directement en magasin, ou de bénéfcier du service de livraison, si celui ci est activé. La récupération en magasin est proposée à l\'acheteur par défaut.<br>En activant l\'option de livraison, vous assurez ce servie à l\'acheteur, soit par vous même, soit par l\'intermédiare d\'une entreprise de livraison.',
        addCoupon: "Créer un code de réduction",
        couponCode: "Code de réduction",
        discountPercent: "Pourcentage (%)",
        discountAmount: "Montant fixe (DA)",
        discountValue: "Valeur",
        discountType: "Type de réduction",
        cgvTitle: "Conditions Générales de Vente",
        cgvText: "Les CGV informent le client sur les conditions de remboursement, les délais de livraison, la garantie, les modalités de retour et autres informations dont le client doit prendre connaissance avant d'éffectuer son achat.",
        wats: "Numéro WhatsApp",
        brandName: "Nom de la boutique sur le site",
        linkTwitter: "Lien Twitter",
        next: "Suivant",
        myOffer: "Mon Offre",
        storeAddress: "Adresse de ma Boutique",
        createStore: "Créer une boutique en ligne",
        login: "Se connecter",
        password: "Mot de passe",
        email: 'E-mail',
        error1_title: "Attention",
        error1_text: "Le nom d'utilisateur doit contenir au minimum 4 caractères",
        error2_text: "Tous les champs doivent être remplis.",
        error3_text: "Votre email est incorrect",
        error4_text: "Vous devez ajouter au moins une photo",
        error5_text: "Vous devez être connecté pour utiliser cette fonction",
        error6_text: "Vous ne pouvez pas vous contacter vous-même.",
        validate: "Valider",
        titre: "Titre de l'annonce",
        etat: "Etat du produit",
        noShipping: "Non disponible",
        yesShipping: "Livraison gratuite",
        shippingPayment: "Paiement à la livraison",
        price: "Prix",
        desc: "Description (facultatif)",
        phone: "Téléphone",
        publicate: "Publier l'annonce",
        cat: "Catégorie",
        scat: "Sous-catégorie",
        currency: "DA",
        limite_pictures: "Le nombre de photo est limité à 3",
        min_picture: "Il doit y avoir au moins une photo",
        tempLinkText: "Votre boutique en ligne est temporairement accessible à l'adresse suivante:",
        subdomainLinkTextWait: "Veuillez patienter quelques heures pour que votre nouvelle adresse soit activée",
        limitProd: "Nombre de produits",
        limitPhotos: "Nombre de photos par produit",
        limitSlides: "Nombre d'images d'accueil (slides)",
        fbChat: "Chat Messenger sur la boutique",
        domainName: "Hébergement de votre nom de domaine (monsite.com)",
        ssl: "Certificat de sécurité SSL (https)",
        apiShipping: "API Yalidine Express (Frais de livraison et création automatique des colis)",
        apiLogin: "API Login Facebook et Google",
        expireDate: "Date d'expiration",
        contactInfosTitle: "Informations de Contact",
        contactInfosText: "Ces information seront affichées à vos clients sur votre boutique en ligne",
        orderAddress: "Adresse",
        address: "Adresse en Français",
        addressAr: "Adresse en Arabe",
        linkFb: "Lien page Facebook",
        linkInsta: "Lien page Instagram",
        save: "Enregistrer",
        renew: "Renouveler",
        renewText: "Vous devez renouveler votre offre chaque mois sur votre espace client, sous peine de voir votre boutique suspendue. Ceci afin de lutter contre les boutiques abondonnées",
        logoTitle: "Logo de la boutique",
        logoText: "Cette image sera affichée sur la boutique. Elle correspond au Logo de votre marque ou de votre commerce.",
        importImage: "Importer une image",
        fileTypeError: "Format non supporté",
        fileSizeError: "Fichier trop grand",
        categories: "Catégories",
        noCat: "Vous n'avez aucune catégorie enregistrée",
        noScat: "Vous n'avez aucune sous-catégorie enregistrée dans la catégorie sélectionnée",
        catFr: "Catégorie en Français",
        catAr: "Catégorie en Arabe",
        scatFr: "Sous-Catégorie en Français",
        scatAr: "Sous-Catégorie en Arabe",
        subCategories: "Sous-Catégories",
        products: "Liste des Produits",
        addProd: "Ajouter un produit",
        prodInfos: "Informations sur le produit",
        designation: "Désignation",
        brand: "Marque",
        promoTx: "Taux de réduction",
        pricePromo: "Prix Réduit",
        picsTitle: "Photos du produit",
        picsText: "Sélectionnez et envoyez les photos du produit, le poids est limité à 250 Ko par photo",
        slidesTitle: "Images d'accueil défilantes (Slides)",
        slidesText: "Sélectionnez et envoyez les images d'accueil, ça peut être des images d'un produit en promotion ou publicitaires, le poids est limité à 500 Ko par photo",
        btnUploadLogoText: "Uploader l'image",
        update: "Mettre à jour",
        options: "Options",
        hideProduct: "Masquer le produit",
        enablePromo: "En promotion",
        setNewBadge: "Badge (Nouveau)",
        prodVariants: "Variants du produit et quantité",
        variantText: '<i class="nc-icon nc-alert-circle-i" style="font-size: 24px"></i><br>Cette section vous permet de définir les choix offerts au client, en matière de couleurs, de tailles etc. et d\'introduire la quantité disponible pour chaque variant. <b>Si certaines valeurs sont manquantes sur les listes, vous pouvez nous contacter pour les ajouter.</b>',
        variantColorAsk: "Est-ce que ce produit est disponible en plusieurs couleurs?",
        variantSizeAsk: "Est-ce que ce produit est disponible en plusieurs tailles?",
        variantFeetAsk: "Est-ce que ce produit est disponible en plusieurs pointures?",
        variantVolumeAsk: "Est-ce que ce produit est disponible en plusieurs contenance (volume)?",
        variantWeightAsk: "Est-ce que ce produit est disponible en plusieurs poids (g, Kg)?",
        generateVariants: "Générer les variants",
        yes: "Oui",
        no: "Non",
        noColorOptions: "Cette couleur n'a pas été trouvée.",
        selectOption: "Sélection...",
        free: "Gratuit",
        hasVariantsAsk: "Est-ce que ce produit possède des variants? (Différentes couleurs ou tailles ou pointure ou volume etc.)",
        color: "Couleur",
        size: "Taille",
        feet: "Pointure",
        volume: "Volume",
        weight: "Poids",
        any: "Aucune",
        firstOptionAsk: "Choisissez une première option si disponible",
        secondOptionAsk: "Choisissez une seconde option si disponible",
        qt: "Quantité",
        qtReal: "Quantité réelle",
        qtVirt: "Quantité virtuelle",
        inputGroupVariantText: "<i class=\"nc-icon nc-alert-circle-i\" style=\"font-size: 24px\"></i><br>Ces champs vous permettent de saisir la quantité et le prix des variants en une fois. La modification se fera sur tous les variants de ce produit.<br><b>AVERTISSEMENT: Si vous souhaitez changer une valeur d'un variant, il est conseillé de le faire individuellement sur leurs propres champs.</b>"
    }

}
export default dict
