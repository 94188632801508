import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Spinner, Button, Input, FormGroup } from "reactstrap";
import dict from '../utils/lang';
import ImageUploader from "react-images-upload";
//import CustomTable from './CustomTable';
import CustomTable from 'react-dynamic-bs-table';
import 'react-dynamic-bs-table/dist/index.css'

const headList = [
    "Marque", "Logo"
]
class CarsDb extends Component {
    constructor(props) {
        super(props);
        this.showNotification = this.props.showNotification
        this.lang = window.sessionStorage.getItem('lang')
        this.onDrop = this.onDrop.bind(this);
        this.form = {}
        this.state = {
            logo: null,
            isLoading: false,
            image: null,
            brandsList: [],
        };
    }
    setFormData(event) {
        this.form[event.target.id] = event.target.value
    }
    //ON DROP IMAGE UPLOAD
    onDrop(pictureFiles, pictureDataURLs) {
        if (pictureFiles.length > 0) {
            this.setState({ image: pictureFiles[0] })
        } else {
            this.setState({ image: null })
        }
    }
    sendBrandData(image) {
        if (this.form.brand_name === "" || this.form.brand_name === undefined) {
            this.showNotification({ place: "tc", message: "Veuillez saisir une marque", type: "warning", icon: "nc-icon nc-bell-55", autoDismiss: 2 })
        } else {
            this.setState({ isLoading: true, image: null })
            let formdata = new FormData();
            formdata.append('file', image)
            formdata.append('session_token', window.sessionStorage.getItem('session_token'))
            formdata.append('brand_name', this.form.brand_name)
            return fetch('https://admin.mydzstore.com/api/cars-brand', {
                method: 'POST',
                body: formdata
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    this.setState({ isLoading: false })
                    this.showNotification(data)
                    //this.loadBrandLogo()
                    this.loadBrandsList()
                })
                .catch((error) => console.error(error));
        }
    }
    loadBrandLogo() {
        return fetch('https://ec.mydzstore.com/api/get-shop-bg', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                    this.setState({ logo: require("assets/img/bg5.jpg").default })
                }
                else {
                    if (data.bg_link === null) {
                        this.setState({ logo: require("assets/img/bg5.jpg").default })
                    }
                    else {
                        this.setState({ logo: data.bg_link })
                    }
                }
            })
            .catch((error) => console.error(error));
    }
    loadBrandsList() {
        this.setState({ isLoading: true })
        return fetch('https://admin.mydzstore.com/api/cars-brand', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token')
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                } else {
                    //console.log(data.data);
                    this.setState({ brandsList: data.data, isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //DISPLAY LOADER
    displayLoader() {
        if (this.state.isLoading) {
            return (
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '100%', width: '100%' }}>
                    <Spinner color="primary" />
                </div>
            )
        }
    }
    componentDidMount() {
        this.loadBrandsList()
    }
    render() {
        return (
            <div className="content">
                <Card style={{ height: '100%' }}>
                    <CardHeader>
                        <CardTitle tag="h5">Marque</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <p>{dict[this.lang].shopBgText}</p>
                        <Row>
                            <Col md="6">
                                {this.displayLoader()}
                                {
                                    <CustomTable
                                        bodyList={this.state.brandsList}
                                        headList={headList}
                                        imageHeight={75}
                                        rowNumber
                                        enablePagination
                                        itemsPerPage={5}
                                        customClass={{ tableClass: "table", theadClass: "text-primary" }}
                                    />
                                }
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <label htmlFor="brand_name">
                                        Marque
                                    </label>
                                    <Input defaultValue="" type="text" id="brand_name" onChange={(event) => this.setFormData(event)} />
                                </FormGroup>
                            </Col>
                            {/* <Col md="3">
                                <div>
                                    {this.state.logo !== null && <img style={{ maxHeight: 200 }} src={this.state.logo} alt="Logo" />}
                                </div>
                            </Col> */}
                            <Col md="3" className="">
                                {this.displayLoader()}
                                {!this.state.isLoading &&
                                    <ImageUploader
                                        withPreview={true}
                                        withIcon={true}
                                        buttonText={dict[this.lang].importImage}
                                        buttonClassName="btn-primary"
                                        onChange={this.onDrop}
                                        imgExtension={[".jpg", ".gif", ".png"]}
                                        maxFileSize={500000}
                                        label="Max 500 Ko. Formats:jpg, png"
                                        singleImage={true}
                                        fileTypeError={dict[this.lang].fileTypeError}
                                        fileSizeError={dict[this.lang].fileSizeError}
                                    />
                                }
                                {this.state.image !== null &&
                                    <Button onClick={() => this.sendBrandData(this.state.image)} className="btn-round btn-sm" color="primary">{dict[this.lang].btnUploadLogoText}</Button>
                                }

                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default CarsDb;
