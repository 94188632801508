import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, FormGroup } from "reactstrap";
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

class ChannelsForm extends Component {
    constructor(props) {
        super(props);
        this.notifRef = React.createRef()
        this.id_confroom = this.props.match.params.id_confroom
        this.form = {}
        this.state = {
            id_confroom: this.props.match.params.id_confroom,
            confroomData: [],
            wilaya_selected: null,
            commune_selected: null,
            wilayaList: [],
            communesList: [],
            communesListFiltered: [],
            isLoading: true,
            isGeocoding: false,
            geocoded: false
        };
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    setFormData(event) {
        if (event.value !== undefined) {
            this.form["commune_code"] = event.commune_code
            if (event.value === "") {
                delete this.form["commune_code"]
            }
        } else {
            this.form[event.target.id] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.id]
            }
        }
    }
    loadChannelData() {
        this.setState({ isLoading: true })
        return fetch('https://ita.imedramdani.com/api/admin/channels/' + this.state.id_confroom, {
            method: 'GET',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {

                    this.setState({ confroomData: data.confroomData, isLoading: false })
                    this.form['room_name'] = data.confroomData.room_name
                    this.form['room_desc'] = data.confroomData.room_desc

                    this.setState({ confroomData: data.confroomData, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }

    //SEND REPORT TO DATABASE
    addChannel(METHODE) {
        return fetch('https://ita.imedramdani.com/api/admin/channels/', {
            method: METHODE,
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                form: this.form,
                id_confroom: this.state.id_confroom
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.showNotification(data)
                    this.setState({ id_confroom: data.id_confroom })
                    this.loadChannelData()
                }
                else {
                    this.showNotification(data)
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }
    componentDidMount = () => {
        this.loadChannelData()
    };

    render() {
        console.log(this.id_confroom);
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>
                    <Col md="12">
                        <Link to={
                            { pathname: "/channels" }
                        }><Button className="btn-round float-left"><i className='nc-icon nc-minimal-left'></i> Retour</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Ajouter/Editer un canal</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        < FormGroup >
                                            <label htmlFor="room_name">
                                                Nom du canal
                                            </label>
                                            <Input defaultValue={this.state.confroomData.room_name} type="text" id="room_name" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        < FormGroup >
                                            <label htmlFor="room_desc">
                                                Description du canal
                                            </label>
                                            <Input placeholder="Décrivez brièvement l'objectif de ce canal" defaultValue={this.state.confroomData.room_desc} type="text" id="room_desc" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>                                   
                                    <Col md="3">
                                        < FormGroup >
                                            <label htmlFor="room_number">
                                                Numéro du canal
                                            </label>
                                            <Input placeholder="Décrivez brièvement l'objectif de ce canal" defaultValue={this.state.confroomData.room_number} type="text" id="room_number" onChange={(event) => this.setFormData(event)} readOnly />
                                        </FormGroup>
                                    </Col>

                                    <Col md="12" className='mt-4'>
                                        {
                                            this.state.id_confroom === "add" &&
                                            <Button onClick={() => this.addChannel('POST')} style={{ width: "100%" }} className="btn-round btn-primary">Ajouter</Button>
                                        }
                                        {
                                            this.state.id_confroom !== "add" &&
                                            <Button onClick={() => this.addChannel('UPDATE')} style={{ width: "100%" }} className="btn-round btn-primary">Mettre à jour</Button>
                                        }

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default ChannelsForm;
