import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, FormGroup } from "reactstrap";
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Select from 'react-select'
import ReportPictures from './ReportPictures';
class ReportForm extends Component {
  constructor(props) {
    super(props);
    this.notifRef = React.createRef()
    this.id_report = this.props.match.params.id_report
    this.form = {}
    this.state = {
      id_report: this.props.match.params.id_report,
      reportData: [],
      id_nature_selected: null,
      naturesList: [],
      isLoading: true,
      isGeocoding: false,
      geocoded: false
    };
  }
  //SHOW NOTIFICATION FUNCTION
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  setFormData(event) {
    if (event.value !== undefined) {
      this.form["id_nature"] = event.value
      if (event.value === "") {
        delete this.form["id_nature"]
      }
    } else {
      this.form[event.target.id] = event.target.value
      if (event.target.value === "") {
        delete this.form[event.target.id]
      }
    }
  }
  loadReportData() {
    this.setState({ isLoading: true })
    return fetch('https://ita.imedramdani.com/api/admin/reports/' + this.state.id_report, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "true") {

          const natureDefaultValue = this.state.naturesList.find(opt => opt.value === data.reportData.id_nature);

          this.setState({ reportData: data.reportData, id_nature_selected: natureDefaultValue, isLoading: false })

        } else if (data.reponse === "false") {
          this.showNotification(data)
        }
        else {
          this.setState({ isLoading: false })
        }
      })
      .catch((error) => console.error(error));
  }
  //LOAD REPORTS NATURES LIST
  loadNatures() {
    let url = 'https://ita.imedramdani.com/api/admin/natures/';
    return fetch(url, {
      method: 'GET',
      headers: {
        'x-my-custom-header': sessionStorage.getItem('id_user'),
        'authorization': sessionStorage.getItem('session_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "true") {
          this.setState({ naturesList: data.naturesList, isLoading: false })
        }
        else {
          this.showNotification(data)
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        console.error(error)
      });

  }
  //GET TOWN AND WILAYA NAMES BY REVERSE GEOCODING USING OSM API
  reverseGeocoding(lat, lng) {
    return fetch('https://nominatim.openstreetmap.org/reverse?lat=' + lat + '&lon=' + lng + '&format=json&accept-language=fr', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        console.error(error)
      });
  }
  //HANDLE COORD
  handleCoord(coord) {
    this.setState({ isGeocoding: true, geocoded: false })
    let lat = coord.split(",")[0]
    let lng = coord.split(",")[1]

    lat = lat !== undefined ? lat.trim() : 0
    lng = lng !== undefined ? lng.trim() : 0

    this.reverseGeocoding(lat, lng).then((data) => {
      console.log(data);
      if (!data.error) {
        let commune = ""
        if (data.address.town !== undefined) {
          commune = data.address.town
        }
        else {
          if (data.address.city !== undefined) {
            commune = data.address.city
          }
          else {
            commune = data.address.village
          }
        }
        let road = data.address.road !== undefined ? data.address.road : null
        let wilaya = data.address.state
        this.form["lat"] = lat
        this.form["lng"] = lng
        this.form["commune"] = commune
        this.form["road"] = road
        this.form["wilaya"] = wilaya

        this.setState({ isGeocoding: false, geocoded: true })
      }
    })
  }
  //SEND REPORT TO DATABASE
  sendReport() {
    return fetch('https://ita.imedramdani.com/api/admin/reports/', {
      method: 'POST',
      headers: {
        'x-my-custom-header': sessionStorage.getItem('id_user'),
        'authorization': sessionStorage.getItem('session_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: this.form,
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "true") {
          this.showNotification(data)
          this.setState({ id_report: data.id_report })
          this.loadReportData()
        }
        else {
          this.showNotification(data)
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }
  //VALIDATE REPORT
  validateReport() {
    return fetch('https://ita.imedramdani.com/api/admin/reports/', {
      method: 'UPDATE',
      headers: {
        'x-my-custom-header': sessionStorage.getItem('id_user'),
        'authorization': sessionStorage.getItem('session_token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id_report: this.state.id_report,
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "true") {
          this.showNotification(data)
          this.loadReportData()
        }
        else {
          this.showNotification(data)
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }
  componentDidMount = () => {
    this.loadNatures()
    if (this.id_report !== "add") {
      this.loadReportData()
    }
  };

  render() {
    console.log(this.state);
    let coord = null
    if (Object.keys(this.state.reportData).length > 0) {

      coord = this.state.reportData.lat + ", " + this.state.reportData.lng
    }
    else if (Object.keys(this.state.reportData).length === 0 && this.id_report === "add" && !this.state.geocoded) {
      coord = ""
    }
    else if (Object.keys(this.state.reportData).length === 0 && this.id_report === "add" && this.state.geocoded) {
      coord = ""
    }
    let reportMarker = ""
    if (Object.keys(this.state.reportData).length > 0) {
      reportMarker = "https://ita.imedramdani.com/?lat=" + this.state.reportData.lat + "&lng=" + this.state.reportData.lng
    }

    return (
      <div className="content">
        <NotificationAlert ref={this.notifRef} />
        <Row>
          <Col md="12">
            <Link to={
              { pathname: "/reports" }
            }><Button className="btn-round float-left"><i className='nc-icon nc-minimal-left'></i> Retour</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              {/* DISPLAY LOADER */}
              {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                <Loader />
              </div>}
              <CardHeader>
                <CardTitle tag="h4">Ajouter un signalement</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="id_nature">
                        Type d'incident
                      </label>
                      <Select value={this.state.id_nature_selected} options={this.state.naturesList} onChange={(event) => { this.setState({ id_nature_selected: event }); this.setFormData(event) }} isSearchable={false} />
                    </FormGroup>
                  </Col>
                  {
                    coord !== null &&
                    <Col md="3">
                      < FormGroup >
                        <label htmlFor="coord">
                          Coordonnées
                        </label>
                        <Input defaultValue={coord} type="text" id="coord" onChange={(event) => this.handleCoord(event.target.value)} />
                      </FormGroup>
                    </Col>
                  }

                  <Col md="6">
                    <FormGroup>
                      <label htmlFor="report_desc">
                        Description (Facultatif)
                      </label>
                      <Input defaultValue={this.state.reportData.report_desc} type="text" id="report_desc" onChange={(event) => this.setFormData(event)} />
                    </FormGroup>
                  </Col>

                  {/* DISPLAY NEWS PICTURE */}

                  {this.state.id_report !== "add" &&
                    <Col md="6" className="mb-4">
                      <ReportPictures showNotification={this.showNotification} id_report={this.state.id_report} />
                    </Col>
                  }
                  {/* DISPLAY MAP */}

                  {this.state.id_report !== "add" && reportMarker !== "" &&
                    <Col md="6" className="mb-4">
                      <iframe src={reportMarker} title="description" style={{ width: "100%", height: "100%" }}></iframe>
                    </Col>
                  }


                  <Col md="12" className='mt-4'>
                    {
                      this.state.id_report !== "add" && this.state.reportData.valid === 0 &&
                      <Button disabled={this.state.disabled} onClick={() => this.validateReport()} style={{ width: "100%" }} className="btn-round btn-success">Valider et rendre visible</Button>
                    }
                    {
                      this.state.id_report !== "add" && this.state.reportData.valid === 0 &&
                      <Button disabled={this.state.disabled} onClick={() => this.validateReport()} style={{ width: "100%" }} className="btn-round btn-warning">Valider et alerter tous les utilisateurs</Button>
                    }
                    {
                      this.state.id_report === "add" && this.state.geocoded === true &&
                      <Button disabled={this.state.disabled} onClick={() => this.sendReport()} style={{ width: "100%" }} className="btn-round btn-primary">Envoyer</Button>
                    }
                    {
                      this.state.id_report === "add" && this.state.isGeocoding === true &&
                      <p className="text-center">Geolocalisation en cours...</p>
                    }

                  </Col>

                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div >
    );
  }
}

export default ReportForm;
