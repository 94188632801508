import React from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from 'react-router-dom';
// import dict from '../utils/lang';
import UsersWid from "components/Widgets/UsersWid";
import ReportsWid from "components/Widgets/ReportsWid";
import CarsCount from "components/Widgets/CarsCount";
import OrdersWaitWid from "components/Widgets/OrdersWaitWid";
import ReportsMap from "components/Widgets/ReportsMap";
// import ChartSellsCount from "components/Widgets/ChartSellsCount";
// import ChartBestSellers from "components/Widgets/ChartBestSellers";
import NotificationAlert from "react-notification-alert";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.notifRef = React.createRef()
    this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
    this.notifRef = React.createRef()
    this.state = {
      offerData: [],
    };
  }
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }

  componentDidMount() {

  }
  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref={this.notifRef} />
          <Row>
            <Col lg="3" md="6" sm="6">
              <UsersWid />
            </Col>
            <Col lg="3" md="6" sm="6">
              <ReportsWid />
            </Col>
            <Col lg="3" md="6" sm="6">
              <CarsCount />
            </Col>
            <Col lg="3" md="6" sm="6">
              <OrdersWaitWid />
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <ReportsMap />
            </Col>
          </Row>

          {/* <Row>
            <Col md="4">
              <ChartBestSellers />
            </Col>
            <Col md="8">
              <ChartSellsCount />
            </Col>
          </Row> */}

        </div>
      </>
    );
  }
}

export default Dashboard;
