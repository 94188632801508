import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, FormGroup } from "reactstrap";
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Select from 'react-select'

class ReportForm extends Component {
    constructor(props) {
        super(props);
        this.notifRef = React.createRef()
        this.id_repair = this.props.match.params.id_repair
        this.form = {}
        this.state = {
            id_repair: this.props.match.params.id_repair,
            repairData: [],
            wilaya_selected: null,
            commune_selected: null,
            wilayaList: [],
            communesList: [],
            communesListFiltered: [],
            isLoading: true,
            isGeocoding: false,
            geocoded: false
        };
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    setFormData(event) {
        if (event.value !== undefined) {
            this.form["commune_code"] = event.commune_code
            if (event.value === "") {
                delete this.form["commune_code"]
            }
        } else {
            this.form[event.target.id] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.id]
            }
        }
    }
    loadrepairData() {
        this.setState({ isLoading: true })
        return fetch('https://ita.imedramdani.com/api/repair/' + this.state.id_repair, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {

                    const wilayaDefaultValue = this.state.wilayaList.find(opt => opt.wilaya_code === data.repairData.wilaya_code);
                    const communeDefaultValue = this.state.communesList.find(opt => opt.commune_code === data.repairData.commune_code);

                    this.setState({ repairData: data.repairData, wilaya_selected: wilayaDefaultValue, commune_selected: communeDefaultValue, isLoading: false })
                    this.form['repair_name'] = data.repairData.repair_name
                    this.form['repair_phone'] = data.repairData.repair_phone
                    this.form['heure_service'] = data.repairData.heure_service
                    this.form['commune_code'] = data.repairData.commune_code
                    this.setState({ repairData: data.repairData, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //LOAD REPORTS NATURES LIST
    loadWilayas() {
        let url = 'https://ita.imedramdani.com/api/wilayas/';
        return fetch(url, {
            method: 'GET',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ wilayaList: data.wilayaList })
                }
                else {
                    this.showNotification(data)
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false })
                console.error(error)
            });
    }
    loadCommunes() {
        let url = 'https://ita.imedramdani.com/api/communes/';
        return fetch(url, {
            method: 'GET',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    if (this.id_repair !== "add") {
                        this.setState({ communesList: data.communesList, communesListFiltered: data.communesList, isLoading: false })
                    }
                    else {
                        this.setState({ communesList: data.communesList, isLoading: false })
                    }
                }
                else {
                    this.showNotification(data)
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false })
                console.error(error)
            });
    }
    //GET TOWN AND WILAYA NAMES BY REVERSE GEOCODING USING OSM API
    reverseGeocoding(lat, lng) {
        return fetch('https://nominatim.openstreetmap.org/reverse?lat=' + lat + '&lon=' + lng + '&format=json&accept-language=fr', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                return data
            })
            .catch((error) => {
                this.setState({ isLoading: false })
                console.error(error)
            });
    }
    //HANDLE COORD
    handleCoord(coord) {
        this.setState({ isGeocoding: true, geocoded: false })
        let lat = coord.split(",")[0]
        let lng = coord.split(",")[1]

        lat = lat !== undefined ? lat.trim() : 0
        lng = lng !== undefined ? lng.trim() : 0

        this.reverseGeocoding(lat, lng).then((data) => {
            if (!data.error) {
                let commune = ""
                if (data.address.town !== undefined) {
                    commune = data.address.town
                }
                else {
                    if (data.address.city !== undefined) {
                        commune = data.address.city
                    }
                    else {
                        commune = data.address.village
                    }
                }
                let road = data.address.road !== undefined ? data.address.road : null
                let wilaya = data.address.state
                this.form["lat"] = lat
                this.form["lng"] = lng
                this.form["commune"] = commune
                this.form["road"] = road
                this.form["wilaya"] = wilaya

                this.setState({ isGeocoding: false, geocoded: true })
            }
        })
    }
    //SEND REPORT TO DATABASE
    addRepair(METHODE) {
        return fetch('https://ita.imedramdani.com/api/repair/', {
            method: METHODE,
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                form: this.form,
                id_repair: this.state.id_repair
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.showNotification(data)
                    this.setState({ id_repair: data.id_repair })
                    this.loadrepairData()
                }
                else {
                    this.showNotification(data)
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }
    filterCommunes(event) {
        let communesListFiltered = this.state.communesList.filter(row => {

            return row['wilaya_code'].toString().toLowerCase().indexOf(event.wilaya_code.toLowerCase()) !== -1


        });
        this.setState({ communesListFiltered: communesListFiltered })
    }
    componentDidMount = () => {
        this.loadWilayas()
        this.loadCommunes().then(() => {
            if (this.id_repair !== "add") {
                this.loadrepairData()
            }
        })
    };

    render() {
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>
                    <Col md="12">
                        <Link to={
                            { pathname: "/repair-services" }
                        }><Button className="btn-round float-left"><i className='nc-icon nc-minimal-left'></i> Retour</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Ajouter un dépanneur</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        < FormGroup >
                                            <label htmlFor="repair_name">
                                                Nom du service de Dépannage/Dépanneur
                                            </label>
                                            <Input defaultValue={this.state.repairData.repair_name} type="text" id="repair_name" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        < FormGroup >
                                            <label htmlFor="repair_phone">
                                                Téléphone
                                            </label>
                                            <Input defaultValue={this.state.repairData.repair_phone} type="text" id="repair_phone" onChange={(event) => this.setFormData(event)} />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup>
                                            <label htmlFor="repair_wilaya">
                                                Wilaya
                                            </label>
                                            <Select value={this.state.wilaya_selected} options={this.state.wilayaList} onChange={(event) => { this.setState({ wilaya_selected: event }); this.filterCommunes(event) }} isSearchable={true} />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup>
                                            <label htmlFor="repair_commune">
                                                Commune
                                            </label>
                                            <Select value={this.state.commune_selected} options={this.state.communesListFiltered} onChange={(event) => { this.setState({ commune_selected: event }); this.setFormData(event) }} isSearchable={true} />
                                        </FormGroup>
                                    </Col>                                    
                                    <Col md="4">
                                        < FormGroup >
                                            <label htmlFor="heure_service">
                                                Heures de travail (Si connues)
                                            </label>
                                            <Input defaultValue={this.state.repairData.heure_service} type="text" id="heure_service" onChange={(event) => this.setFormData(event)} placeholder="مثال: السبت - الخميس من الساعة 8 صباحًا حتى الساعة 8 مساءً" />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12" className='mt-4'>
                                        {
                                            this.state.id_repair === "add" &&
                                            <Button onClick={() => this.addRepair('POST')} style={{ width: "100%" }} className="btn-round btn-primary">Ajouter</Button>
                                        }
                                        {
                                            this.state.id_repair !== "add" &&
                                            <Button onClick={() => this.addRepair('UPDATE')} style={{ width: "100%" }} className="btn-round btn-primary">Mettre à jour</Button>
                                        }

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default ReportForm;
