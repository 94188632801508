import '../assets/css/Login.css';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Input, Row, Button, Spinner } from "reactstrap";
import Footer from "../components/Footer/Footer";
import NotificationAlert from "react-notification-alert";

class Login extends Component {
  constructor(props) {
    super(props)
    this.email = ""
    this.password = ""
    this.setUserData = this.props.setUserData
    this.notifRef = React.createRef();
    this.state = {
      isLoading: false,
    }
  }
  setEmail = (event) => {
    this.email = event.target.value
  }
  setPassword = (event) => {
    this.password = event.target.value
  }
  showNotification(data) {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }
  loginFunc() {
    this.setState({ isLoading: true })
    return fetch('https://ita.imedramdani.com/api/admin/login/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.email,
        password: this.password
      })
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === 'true') {
          window.sessionStorage.setItem('loggedIn', "true")
          window.sessionStorage.setItem('email', data.email)
          window.sessionStorage.setItem('session_token', data.session_token)
          window.sessionStorage.setItem('id_user', data.id_user)
          this.setState({ isLoading: false })
          this.setUserData(true)
        } else if (data.reponse === 'false') {
          this.setState({ isLoading: false })
          this.showNotification(data)
        }
      })
      .catch((error) => console.error(error));
  }
  displayLoader() {
    if (this.state.isLoading) {
      return (
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
          <Spinner color="primary" />
        </div>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Button onClick={() => this.loginFunc()} color="info" className="btn-round mb-3 btn-block">Se Connecter</Button>
        </div>
      )
    }
  }
  render() {
    return (
      <div className="wrapper wrapper-full-page ps">
        <NotificationAlert ref={this.notifRef} />
        <div className="full-page section-image">
          <div className="login-page">
            <div className="container">
              <Row>
                <div className="ml-auto mr-auto col-md-6 col-lg-4">
                  <form action="" method="" className="form">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader className="text-right">
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-single-02"></i></span>
                          </div>
                          <Input placeholder="Email" type="email" className="form-control" onChange={this.setEmail} />
                        </div>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="nc-icon nc-key-25"></i></span>
                          </div>
                          <Input placeholder="Mot de passe..." type="password" autoComplete="off" className="form-control" onChange={this.setPassword} />
                        </div>
                      </CardBody>
                      <CardFooter>
                        {this.displayLoader()}
                      </CardFooter>
                    </Card>
                  </form>
                </div>
              </Row>
            </div>
            <div className="full-page-background"></div>
          </div>
        </div>
        <Footer fluid />
      </div>

    )
  }
}

export default Login;