import Dashboard from "views/Dashboard.js";
import CustomersList from "views/CustomersList";
import UserForm from "views/UserForm";
import ReportsList from "views/ReportsList";
import ReportForm from "views/ReportForm";
import CarsBrands from 'views/CarsBrands';
import RepairServices from 'views/RepairServices';
import RepairServicesForm from 'views/RepairServicesForm';
import Channels from "views/Channels";
import ChannelsForm from "views/ChannelsForm";

var routes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    name_ar: "لوحة القيادة",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
  },
  {
    path: "/reports",
    name: "Signalements",
    name_ar: "عملائي",
    icon: "nc-icon nc-alert-circle-i",
    component: ReportsList,
  },   
  {
    path: "/reports/report-form/:id_report",
    name: "Signalements",
    name_ar: "عملائي",
    icon: "nc-icon nc-tag-content",
    component: ReportForm,
    display: false
  },  
  {
    path: "/users",
    name: "Utilisateurs",
    name_ar: "عملائي",
    icon: "nc-icon nc-single-02",
    component: CustomersList,
  },
  {
    path: "/users/users-form/:id_user",
    name: "Fiche Utilisateur",
    icon: "nc-icon nc-tag-content",
    component: UserForm,
    display: false
  },  
  {
    path: "/repair-services/service-form/:id_repair",
    name: "Fiche Service de Dépannage",
    icon: "nc-icon nc-tag-content",
    component: RepairServicesForm,
    display: false
  },
  {
    path: "/repair-services",
    name: "Dépannage",
    icon: "nc-icon nc-settings",
    component: RepairServices,
    display: true
  },
  {
    path: "/cars-brands",
    name: "Cars Brands",
    icon: "nc-icon nc-tag-content",
    component: CarsBrands,
    display: false
  },
  {
    path: "/channels",
    name: "Canaux",
    icon: "nc-icon nc-headphones",
    component: Channels,
    display: true
  },
  {
    path: "/channels/channels-form/:id_confroom",
    name: "",
    icon: "",
    component: ChannelsForm,
    display: false
  },
];
export default routes;
