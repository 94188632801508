import React, { Component } from 'react';
import NotificationAlert from "react-notification-alert";
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Input } from "reactstrap";
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';

class RepairServices extends Component {
    constructor(props) {
        super(props);
        this.itemsPerPage = 10
        this.numberOfPages = null
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            repairList: [],
            repairListFiltered: [],
            currentPage: 1,
        };
    }

    //Handle next page button
    nextPage() {
        if (this.state.currentPage < this.numberOfPages) {
            this.setState({ currentPage: this.state.currentPage + 1 })
        }
    }
    //Handle previous page button
    previousPage() {
        if (this.state.currentPage > 1) {
            this.setState({ currentPage: this.state.currentPage - 1 })
        }
    }
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    searchFor(event) {
        let repairListFiltered = this.state.repairList.filter(row => {
            const keys = Object.keys(row)
            return keys.some(key => {
                if (row[key] !== null) {
                    return row[key].toString().toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
                } else {
                    return false
                }
            })
        });
        this.setState({ repairListFiltered: repairListFiltered, currentPage: 1 })
    }
    loadRepair() {
        return fetch('https://ita.imedramdani.com/api/repair/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ repairList: data.repairList, repairListFiltered: data.repairList, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    listRepair() {
        let startingRow = (this.state.currentPage - 1) * this.itemsPerPage
        let endingRow = this.state.currentPage * this.itemsPerPage
        if (this.state.repairListFiltered.length === 0) {
            return (
                <p>Vous n'avez encore ajouté aucun service de dépannage</p>
            )
        } else {
            return (
                <Table >
                    <thead className="text-primary">
                        <tr>
                            <th>Nom</th>
                            <th>Téléphone</th>
                            <th>Wilaya</th>
                            <th>Commune</th>
                            <th>Ajouté le</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.repairListFiltered.slice(startingRow, endingRow).map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td>{value.repair_name}</td>
                                    <td>{value.repair_phone}</td>
                                    <td>{value.wilaya_name_ascii}</td>
                                    <td>{value.commune_name_ascii}</td>
                                    <td>{value.date_heure_h}</td>
                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/repair-services/service-form/" + value.id_repair,
                                                id_repair: value.id_repair
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                        <Button className="btn-sm btn-round btn-icon btn-outline-danger mr-2" onClick={() => this.removeRepair(value.id_repair)}>
                                            <i className="nc-icon nc-simple-remove"></i>
                                        </Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //REMOVE PRODUCT BY ID
    removeRepair(id_repair) {
        let confirmAction = window.confirm("Êtes vous sûr de vouloir supprimer ce service de dépannage ?");
        if (confirmAction) {
            return fetch('https://ita.imedramdani.com/api/repair/', {
                method: 'DELETE',
                headers: {
                    'x-my-custom-header': sessionStorage.getItem('id_user'),
                    'authorization': sessionStorage.getItem('session_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id_repair: id_repair
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    this.showNotification(data)
                    this.loadRepair()
                })
                .catch((error) => console.error(error));
        }

    }
    componentDidMount() {
        this.loadRepair()
    }
    render() {
        let numberOfPages = Math.ceil(this.state.repairListFiltered.length / this.itemsPerPage)
        this.numberOfPages = numberOfPages
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>
                    <Col md="6">
                        <Link to="/repair-services/service-form/add">
                            <Button className="btn-round btn-ihg">Ajouter un dépanneur</Button>
                        </Link>
                    </Col>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Liste des services de dépannage</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Input type="text" onChange={(event) => this.searchFor(event)} placeholder="Trouver..." />
                                {this.listRepair()}
                                <nav>
                                    <ul className='pagination'>
                                        <li className='page-item'><button className='page-link btn btn-sm mr-1' onClick={() => this.previousPage()}>Préc.</button></li>
                                        <li className='page-item'><button className='page-link btn btn-sm mr-1'>{this.state.currentPage + " / " + numberOfPages}</button></li>
                                        <li className='page-item'><button className='page-link btn btn-sm' onClick={() => this.nextPage()}>Suivant</button></li>
                                    </ul>
                                </nav>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default RepairServices;
