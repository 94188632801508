import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Spinner } from "reactstrap";
import ImageUploader from "react-images-upload";

class ReportPictures extends Component {
    constructor(props) {
        super(props);
        this.showNotification = this.props.showNotification
        this.onDrop = this.onDrop.bind(this);
        this.id_report = this.props.id_report

        this.state = {
            isLoading: false,
            isLoadingPics: true,
            picturesList: [],
            image: null
        };
    }
    //ON DROP IMAGE UPLOAD
    onDrop(pictureFiles, pictureDataURLs) {
        if (pictureFiles.length > 0) {
            this.setState({ image: pictureFiles })
        } else {
            this.setState({ image: null })
        }
    }
    sendImageButtonHandler = () => {
        this.state.image.forEach(element => {
            this.sendImage(element)
        });
    }
    //SEND IMAGE TO SERVER 
    sendImage(image) {
        console.log(this.id_report);
        this.setState({ isLoading: true, image: null })
        let formdata = new FormData();
        formdata.append('file', image)
        formdata.append('id_report', this.id_report)
        return fetch('https://ita.imedramdani.com/api/admin/picture-upload/', {
            method: 'POST',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
            },
            body: formdata
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                console.log(data);
                this.setState({ isLoading: false })
                this.showNotification(data)
                this.loadImage()
            })
            .catch((error) => console.error(error));
    }
    //GET IMAGE FROM SERVER
    loadImage() {
        return fetch('https://ita.imedramdani.com/api/admin/pictures/', {
            method: 'POST',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_report: this.id_report,
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ picturesList: data.picturesList, isLoadingPics: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //REMOVE IMAGE
    removeImage(id_pic) {
        return fetch('https://ita.imedramdani.com/api/admin/pictures/', {
            method: 'DELETE',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_pic: id_pic
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.showNotification(data)
                    this.loadImage()
                }
                else {
                    this.showNotification(data)
                }
            })
            .catch((error) => console.error(error));
    }
    //DISPLAY LOADER
    displayUploaderLoader() {
        if (this.state.isLoading) {
            return (
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '100%', width: '100%' }}>
                    <Spinner color="primary" />
                </div>
            )
        }
    }
    //DISPLAY LOADER
    displayPhotosLoader() {
        if (this.state.isLoadingPics) {
            return (
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', position: 'absolute', height: '100%', width: '100%' }}>
                    <Spinner color="primary" />
                </div>
            )
        }
    }
    componentDidMount() {
        this.loadImage()
    }
    render() {
        return (
            <Card style={{ height: '100%' }}>
                <CardHeader>
                    <CardTitle as="h4">Photos du signalement</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="8">
                            <Row className="justify-content-center">
                                {/* {this.displayPhotosLoader()} */}
                                {this.state.picturesList.length > 0 &&
                                    this.state.picturesList.map(picture => {
                                        let path = picture.pic_path
                                        return (
                                            < Col key={picture.id_pic} md="3" className="mb-2">
                                                < img style={{ width: "100%" }} src={path} alt="news" />
                                                <Button className="btn-danger btn-fill btn-sm btn-icon btn-round" style={{ position: 'absolute', bottom: 0, right: 0, margin: 0 }} onClick={() => this.removeImage(picture.id_pic)}><i className="nc-icon nc-simple-remove"></i></Button>
                                            </Col>
                                        )
                                    })

                                }
                                {this.state.picturesList.length === 0 &&

                                    <Col md="12" className="mb-2">
                                        <p className='text-info'>Ce signalement n'a pas encore de photo. Cliquez sur le bouton pour en ajouter une.</p>
                                    </Col>

                                }
                            </Row>
                        </Col>
                        <Col md="4" className="d-flex justify-content-center flex-column">
                            {this.displayUploaderLoader()}
                            {!this.state.isLoading &&
                                <ImageUploader
                                    withPreview={true}
                                    withIcon={true}
                                    buttonText="Importer une image"
                                    onChange={this.onDrop}
                                    imgExtension={[".jpg", ".gif", ".png"]}
                                    maxFileSize={500000}
                                    label="Max 500 Ko. Formats:jpg, png, jpeg"
                                    singleImage={false}
                                    fileTypeError="Format non pris en charge"
                                    fileSizeError="Image trop grande"
                                />
                            }
                            {this.state.image !== null &&
                                <Button onClick={() => this.sendImageButtonHandler()} className="btn-round btn-sm" color="primary">Envoyer</Button>
                            }
                        </Col>
                    </Row>
                </CardBody >
            </Card >

        );
    }
}

export default ReportPictures;