import React, { Component } from "react";
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";
import Loader from '../Loader/Loader'
import Iframe from 'react-iframe'
class ReportsMap extends Component {
    constructor(props) {
        super(props);
        this.state = {

            isLoading: false,
        };
    }
    getRevenueChart() {

    }
    componentDidMount() {

    }
    render() {
        return (
            <Iframe url="https://ita.imedramdani.com/"
            width="100%"
            height="600px"
            id=""
            className=""
            display="block"
            position="relative" />
            // <Card>
            //     {/* DISPLAY LOADER */}
            //     {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
            //         <Loader />
            //     </div>}
            //     <CardHeader>
            //         <CardTitle tag="h5">Répartition des signalements</CardTitle>
            //     </CardHeader>
            //     <CardBody>

            //     </CardBody>
            //     <CardFooter>
            //         <hr />
            //         <div className="stats" onClick={() => this.getRevenueChart()}>
            //             <i className="fas fa-history" /> Actualiser
            //         </div>
            //     </CardFooter>
            // </Card>
        );
    }
}

export default ReportsMap;
