import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import Loader from '../Loader/Loader'

class RevenueWid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            count: 0
        }
    }

    getReports() {
        this.setState({ isLoading: true })
        return fetch('https://ita.imedramdani.com/api/admin/dashboard/', {
            method: 'GET_REPORTS',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "false") {
                    this.setState({ isLoading: false })
                } else if (data.reponse === "true") {
                    this.setState({ isLoading: false, count: data.count })
                }
            })
            .catch((error) => console.error(error));
    }

    componentDidMount() {
        this.getReports()
    }

    render() {
        return (
            <Card className="card-stats">
                {/* DISPLAY LOADER */}
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}
                <CardBody>
                    <Row>
                        <Col md="3" xs="3">
                            <div className="icon-big text-center icon-success">
                                <i className="nc-icon nc-alert-circle-i text-danger" />
                            </div>
                        </Col>
                        <Col md="9" xs="9">
                            <div className="numbers">
                                <p className="card-category">Signalements</p>
                                <CardTitle tag="p">{this.state.count}</CardTitle>
                                <p />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <hr />
                    <div className="stats" onClick={() => this.getReports()}>
                        <i className="fas fa-sync-alt" /> Actualiser
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default RevenueWid;
