import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import '../assets/css/myshop.css';
import Loader from 'components/Loader/Loader';
import { Link } from 'react-router-dom';

class UserForm extends Component {
  constructor(props) {
    super(props)
    this.notifRef = React.createRef()
    this.id_user = this.props.match.params.id_user
    this.state = {
      userData: [],
      isLoading: true
    }
  }
  //SHOW NOTIFICATION FUNCTION
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }

  //LOAD CUSTOMER DATA
  loadUserData() {
    return fetch('https://ita.imedramdani.com/api/users/' + this.id_user, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then((reponse) => reponse.json())
      .then((data) => {
        if (data.reponse === "true") {

          this.setState({ userData: data.userData, isLoading: false })
        } else if (data.reponse === "false") {
          this.showNotification(data)
        }
        else {
          this.setState({ isLoading: false })
        }
      })
      .catch((error) => console.error(error));
  }
  displayCar() {
    let array = []
    // console.log(Object.keys(this.state.userData).length);
    if (Object.keys(this.state.userData).length > 0) {
      console.log(this.state.userData.cars);
      if (this.state.userData.cars.length > 0) {

        this.state.userData.cars.map((car, index) => {
          array.push(
            <Col md="3" key={index} className="text-center">
              {/* <img height={50} src={'https://ita.imedramdani.com/images/' + car.brand_logo} alt="avatar" /> */}
              <img height={50} src={'https://ita.imedramdani.com/' + car.model_pic} alt="avatar" />
              <p style={{ fontSize: 20 }}>{car.engine}</p>
              <p style={{ fontSize: 20 }}>{car.car_year}</p>
            </Col>

          )
        })

        return (
          array
        )
      }
    }
  }
  componentDidMount() {
    this.loadUserData()
  }
  render() {
    return (
      <div className="content">
        <NotificationAlert ref={this.notifRef} />
        <Row>
          <Col md="12">
            <Link to={
              { pathname: "/users" }
            }><Button className="btn-round float-left"><i className='nc-icon nc-minimal-left'></i> Retour</Button>
            </Link>
          </Col>
          <Col md="4" sm="12">
            <Card>
              {/* DISPLAY LOADER */}
              {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                <Loader />
              </div>}
              <CardHeader>
                <CardTitle tag="h4">Infos de l'utilisateur</CardTitle>
              </CardHeader>
              <CardBody>
                <div className='mb-4' style={{ textAlign: 'center' }}><img style={{ borderRadius: 100 }} height={200} src={this.state.userData.avatar} alt="avatar" />
                </div>

                <Row>
                  <Col>
                    <p className='infosTitle'>Nom</p>
                    <p className='infosData'>{this.state.userData.lastname !== null ? this.state.userData.lastname : "-"}</p>
                    <p className='infosTitle'>Prénom</p>
                    <p className='infosData'>{this.state.userData.firstname !== null ? this.state.userData.firstname : "-"}</p>
                    <p className='infosTitle'>E-mail</p>
                    <p className='infosData'>{this.state.userData.email !== null ? this.state.userData.email : "-"}</p>
                  </Col>
                  <Col>
                    <p className='infosTitle'>Téléphone</p>
                    <p className='infosData'>{this.state.userData.phone !== null ? this.state.userData.phone : "-"}</p>
                    <p className='infosTitle'>Type de compte</p>
                    <p className='infosData'>{this.state.userData.user_type}</p>
                    <p className='infosTitle'>Date d'inscription</p>
                    <p className='infosData'>{this.state.userData.register_date}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" sm="12">
            <Card>
              {/* DISPLAY LOADER */}
              {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                <Loader />
              </div>}
              <CardHeader>
                <CardTitle tag="h4">Véhicules ajoutés</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    this.displayCar()
                  }
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserForm;
