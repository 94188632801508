import React, { Component } from 'react';
import NotificationAlert from "react-notification-alert";
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Input } from "reactstrap";
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';

class Channels extends Component {
    constructor(props) {
        super(props);
        this.itemsPerPage = 10
        this.numberOfPages = null
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            confroomsList: [],
            confroomsListFiltered: [],
            currentPage: 1,
        };
    }

    //Handle next page button
    nextPage() {
        if (this.state.currentPage < this.numberOfPages) {
            this.setState({ currentPage: this.state.currentPage + 1 })
        }
    }
    //Handle previous page button
    previousPage() {
        if (this.state.currentPage > 1) {
            this.setState({ currentPage: this.state.currentPage - 1 })
        }
    }
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    searchFor(event) {
        let confroomsListFiltered = this.state.confroomsList.filter(row => {
            const keys = Object.keys(row)
            return keys.some(key => {
                if (row[key] !== null) {
                    return row[key].toString().toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
                } else {
                    return false
                }
            })
        });
        this.setState({ confroomsListFiltered: confroomsListFiltered, currentPage: 1 })
    }
    loadChannels() {
        return fetch('https://ita.imedramdani.com/api/admin/channels/', {
            method: 'GET',
            headers: {
                'x-my-custom-header': sessionStorage.getItem('id_user'),
                'authorization': sessionStorage.getItem('session_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ confroomsList: data.confroomsList, confroomsListFiltered: data.confroomsList, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    listChannels() {
        let startingRow = (this.state.currentPage - 1) * this.itemsPerPage
        let endingRow = this.state.currentPage * this.itemsPerPage
        if (this.state.confroomsListFiltered.length === 0) {
            return (
                <p>Vous n'avez encore ajouté de canal de communication</p>
            )
        } else {
            return (
                <Table >
                    <thead className="text-primary">
                        <tr>
                            <th>Nom du canal</th>
                            <th>Description</th>
                            <th>Limite d'utilisateurs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.confroomsListFiltered.slice(startingRow, endingRow).map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td>{value.room_name}</td>
                                    <td>{value.room_desc}</td>
                                    <td>100</td>

                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/channels/channels-form/" + value.id_confroom,
                                                id_confroom: value.id_confroom
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    componentDidMount() {
        this.loadChannels()
    }
    render() {
        let numberOfPages = Math.ceil(this.state.confroomsListFiltered.length / this.itemsPerPage)
        this.numberOfPages = numberOfPages
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>
                    {/* <Col md="6">
                        <Link to="/repair-services/service-form/add">
                            <Button className="btn-round btn-ihg">Ajouter un canal</Button>
                        </Link>
                    </Col> */}
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Liste des canaux</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Input type="text" onChange={(event) => this.searchFor(event)} placeholder="Trouver..." />
                                {this.listChannels()}
                                <nav>
                                    <ul className='pagination'>
                                        <li className='page-item'><button className='page-link btn btn-sm mr-1' onClick={() => this.previousPage()}>Préc.</button></li>
                                        <li className='page-item'><button className='page-link btn btn-sm mr-1'>{this.state.currentPage + " / " + numberOfPages}</button></li>
                                        <li className='page-item'><button className='page-link btn btn-sm' onClick={() => this.nextPage()}>Suivant</button></li>
                                    </ul>
                                </nav>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Channels;
