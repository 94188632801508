import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/paper-dashboard.min.css";
import "assets/demo/demo.min.css";

import AdminLayout from "layouts/Admin.js";

import { LoginProvider } from './utils/context';

const lang = window.sessionStorage.getItem('lang')
if (lang === null || lang === undefined) {
  window.sessionStorage.setItem('lang', 'fr')
}

ReactDOM.render(
  <BrowserRouter>
    <LoginProvider>
      <Switch>
        <Route path="" render={(props) => <AdminLayout {...props} />} />
        <Redirect to="/dashboard" />
      </Switch>
    </LoginProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
